var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"justify-center":"","id":"login","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","light":"","max-width":"100%","width":"400"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("reset-pwd-title"))+" ")])]},proxy:true}])},[_c('v-card-text',{staticClass:"text-center"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{staticClass:"container-logo-small-pages"}),(!_vm.resetPasswordError && !_vm.resetSuccess)?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required","vid":"password_confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"color":"secondary","append-icon":_vm.view_password ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.view_password ? 'password' : 'text',"error-messages":errors,"success":valid,"label":_vm.$t('reset-pwd-new-password'),"prepend-icon":"mdi-lock","required":""},on:{"click:append":function () { return (_vm.view_password = !_vm.view_password); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password_confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"color":"secondary","append-icon":_vm.view_password_confirm ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.view_password_confirm ? 'password' : 'text',"error-messages":errors,"success":valid,"label":_vm.$t('reset-pwd-repeat'),"prepend-icon":"mdi-lock","required":""},on:{"click:append":function () { return (_vm.view_password_confirm = !_vm.view_password_confirm); }},model:{value:(_vm.repeat_password),callback:function ($$v) {_vm.repeat_password=$$v},expression:"repeat_password"}})]}}],null,true)})],1):_vm._e(),(_vm.resetSuccess)?_c('div',[_c('div',{staticStyle:{"height":"10px"}}),_c('v-alert',{attrs:{"type":"success","dense":""}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("reset-pwd-success")))])])],1):_vm._e(),(_vm.resetPasswordError)?_c('div',[_c('div',{staticStyle:{"height":"10px"}}),_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.resetPasswordError))])])],1):_vm._e(),_c('div',{staticStyle:{"height":"10px"}}),(!_vm.resetSuccess && !_vm.passwordSuccess && !_vm.resetPasswordError)?_c('v-btn',{attrs:{"disabled":invalid || !validated || _vm.resetting_password,"color":"primary"},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(_vm._s(_vm.$t("reset-pwd-change")))]):_vm._e(),(_vm.resetSuccess)?_c('v-btn',{attrs:{"color":"primary","to":"login"}},[_vm._v("Login")]):_vm._e(),(_vm.resetPasswordError)?_c('v-btn',{attrs:{"color":"primary","to":"recovery"}},[_vm._v(_vm._s(_vm.$t("reset-pwd-reset")))]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }